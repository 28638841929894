import styled from "styled-components"

export const Header = styled.header`
  width: 100%;
  height: 10vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`
export const ImageWrapper = styled.div`
  width: 200px;
`
export const SocialLinksWrapper = styled.div`
  ul {
    display: flex;
  }

  li {
    margin-left: 10px;
  }
`
