import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.footer`
  width: 100%;
`
export const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`
export const ImageWrapper = styled.div`
  width: 190px;
`
export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    margin-top: 30px;
  `}

  a {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    margin-top: 10px;

    &:hover {
      color: var(--main-color);
      text-decoration: underline;
    }
  }
`

export const InternalLinks = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
    align-items: center;
    margin-top: 30px;
  `}

  a {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    margin-top: 10px;

    &:hover {
      color: var(--main-color);
      text-decoration: underline;
    }
  }
`
