import React from "react"
import * as S from "./styled"

const Signature = () => {
  return (
    <S.Container>
      Desenvolvido com carinho por{" "}
      <a href="https://qrvdesign.com.br">QRV design</a>
    </S.Container>
  )
}

export default Signature
