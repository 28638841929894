import React, { useEffect } from "react"
import GlobalStyles from "../../styles/global"

import Header from "../Header"
import Footer from "../Footer"
import Signature from "../Signature"

const Layout = ({ children }) => {
  useEffect(() => {
    console.log(
      "%cQRV DESIGN",
      "color:#ffd200; background-color:#000; padding:5px 10px; font-size:30px; font-family: inversionz;"
    )
  }, [])

  return (
    <>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
      <Signature />
    </>
  )
}
export default Layout
