import React from "react"
import * as S from "./styled"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//* Icons
import { Instagram } from "@styled-icons/remix-line/Instagram"
import { FacebookBox } from "@styled-icons/remix-line/FacebookBox"
import { Whatsapp } from "@styled-icons/remix-line/Whatsapp"

const socialLinksData = [
  {
    icon: Instagram,
    href: "https://www.instagram.com/paulinho_imoveis/",
    label: "@paulinho_imoveis",
  },
  {
    icon: FacebookBox,
    href: "https://www.facebook.com/paulinhoimoveis",
    label: "@paulinhoimoveis",
  },
  {
    icon: Whatsapp,
    href: "https://api.whatsapp.com/send?phone=5513997883549",
    label: "(13) 9 99788-3549",
  },
]

const internalLinks = [
  {
    href: "https://www.imoveisemperuibe.com.br/empresa",
    label: "Sobre nós",
  },
  {
    href: "https://www.imoveisemperuibe.com.br/trabalhe-conosco",
    label: "Trabalhe conosco",
  },
  {
    href: "https://www.imoveisemperuibe.com.br/contato",
    label: "Fale conosco",
  },
]

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 190) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Footer = () => {
  return (
    <S.Container>
      <S.ContentWrapper>
        <S.ImageWrapper>
          <Image />
        </S.ImageWrapper>
        <S.SocialLinks>
          {socialLinksData.map((link, key) => {
            const Icon = link.icon
            return (
              <a href={link.href} key={key}>
                <Icon size="30" />
                {link.label}
              </a>
            )
          })}
        </S.SocialLinks>
        <S.InternalLinks>
          {internalLinks.map((link, key) => (
            <a key={key} href={link.href}>
              {link.label}
            </a>
          ))}
        </S.InternalLinks>
      </S.ContentWrapper>
    </S.Container>
  )
}
export default Footer
