import React, { useEffect } from "react"

import * as S from "./styled"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//* Icons
import { Instagram } from "@styled-icons/remix-line/Instagram"
import { FacebookBox } from "@styled-icons/remix-line/FacebookBox"
import { Whatsapp } from "@styled-icons/remix-line/Whatsapp"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const socialLinksData = [
  {
    icon: Instagram,
    href: "https://www.instagram.com/paulinho_imoveis/",
  },
  {
    icon: FacebookBox,
    href: "https://www.facebook.com/paulinhoimoveis",
  },
  {
    icon: Whatsapp,
    href: "https://api.whatsapp.com/send?phone=5513997883549",
  },
]

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Header = () => {
  useEffect(() => {
    const tl = gsap.timeline()
    tl.to("header", { background: "rgba(27, 109, 236, 1)" })
      .to(".image-wrapper", { opacity: 0 }, "<")
      .to(".home-text-wrapper", { y: 150 }, "<")
    ScrollTrigger.create({
      trigger: ".show",
      end: "15%",
      scrub: 0.5,
      animation: tl,
    })
  }, [])

  return (
    <S.Header>
      <S.HeaderWrapper>
        <S.ImageWrapper>
          <Image />
        </S.ImageWrapper>
        <S.SocialLinksWrapper>
          <ul>
            {socialLinksData.map((link, key) => {
              const Icon = link.icon
              return (
                <li key={key}>
                  <a href={link.href}>
                    <Icon size="30" color="#fff" />
                  </a>
                </li>
              )
            })}
          </ul>
        </S.SocialLinksWrapper>
      </S.HeaderWrapper>
    </S.Header>
  )
}
export default Header
