import styled from "styled-components"

export const Container = styled.section`
  background: var(--main-color);
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-weight: 600;

  a {
    margin-left: 5px;
    color: #fff;
  }
`
